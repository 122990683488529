<template>
  <v-container fluid class="registration-container pt-10 px-3">
    <h1>{{ $t("register.card.title") }}</h1>
    <div class="description" v-html="$t('register.card.description')" />
    <ResponseMessage :response="response" />
    <v-card elevation="0" class="pa-5 mt-8">
      <div class="d-flex flex-column flex-md-row">
        <div class="img-card-container mx-auto mb-6 mb-md-0" v-if="step === 0">
          <v-img src="/img_layout/card.png"></v-img>
        </div>
        <div
          class="d-flex flex-column justify-center align-center align-sm-start"
        >
          <v-card-title>{{
            $t(`register.card.step-${step}.text`)
          }}</v-card-title>
          <v-card-text class="text-body-0 primary--text font-weight-normal">
            <p>{{ $t(`register.card.step-${step}.sub`) }}</p></v-card-text
          >
        </div>
      </div>

      <v-row
        no-gutters
        v-if="step > 0"
        align="center"
        class="mb-6 px-0 px-sm-3"
      >
        <v-col cols="4" sm="2">
          <div
            class="text-body-0 font-weight-bold"
            v-html="`Passo ${step} di 3`"
          />
        </v-col>
        <v-col cols="8" sm="10">
          <v-progress-linear :value="progress" />
        </v-col>
        <v-col cols="12" class="mt-3"><v-divider /></v-col>
      </v-row>
      <!-- STEP 0: inserimento fidelityCard -->
      <div v-if="step === 0 && isRegisterWithCard">
        <v-form ref="form-1" @submit.prevent>
          <v-text-field
            :placeholder="$t('register.card.step-0.placeholder')"
            required
            outlined
            dense
            type="tel"
            v-model="userData.fidelityCard"
          />
          <v-row no-gutters justify="space-between">
            <v-col cols="12" sm="5">
              <v-btn
                depressed
                block
                x-large
                v-html="$t('register.card.step-0.btnBack')"
                @click="back"
                class="mb-5 mb-sm-0"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-btn
                depressed
                color="primary"
                block
                x-large
                :disabled="!userData.fidelityCard"
                v-html="$t('register.card.step-0.btnConfirm')"
                @click="findUser('form-1')"
            /></v-col>
          </v-row>
        </v-form>
      </div>

      <!-- STEP 1: dati personali -->
      <UserData
        v-if="step === 1"
        :userData.sync="userData"
        :supportData.sync="supportData"
        :hasFidelityCard="true"
        :askFirstName="askFirstName"
        :askLastName="askLastName"
        :askFiscalCode="askFiscalCode"
        :isRegisterWithCard="isRegisterWithCard"
        :isFiscalCode.sync="isFiscalCode"
        :registrationType="registrationType"
        :isSocialLogin="isSocialLogin"
        @back="goToPreviousStep(true)"
        @submit="onUserDataSubmit"
      />

      <!-- STEP 2: indirizzo -->
      <UserAddress
        v-if="step === 2"
        :userData.sync="userData"
        :supportData.sync="supportData"
        @back="goToPreviousStep"
        @submit="onUserDataSubmit"
      />

      <!-- STEP 3: password e privacy -->
      <UserPasswordAndPrivacy
        class="mt-5"
        v-if="step === 3"
        :userData.sync="userData"
        :supportData.sync="supportData"
        :response="response"
        @back="goToPreviousStep"
        @submit="registerUser"
      />
    </v-card>
  </v-container>
</template>
<style lang="scss">
.registration-container {
  color: var(--v-primary-base);
  h1 {
    font-size: 50px !important;
  }
  .description {
    font-size: 20px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
    p {
      font-weight: 400 !important;
    }
    .img-card-container {
      @media #{map-get($display-breakpoints, 'sm-only')} {
        width: 50%;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 16px !important;
    }
    .v-card {
      height: auto;
    }
  }
}
</style>

<script>
import UserData from "@/components/profile/form/UserData";
import UserAddress from "@/components/profile/form/UserAddress";
import UserPasswordAndPrivacy from "@/components/profile/form/UserPasswordAndPrivacy";
import ResponseMessage from "@/components/ResponseMessage";
import AnalyticsService from "~/service/analyticsService";
import { setPersonInfo } from "~/service/ebsn";
import TigrosRegistrationService from "@/service/tigrosRegistrationService";
import SecurityService from "~/service/securityService";
import SocialService from "~/service/socialService";
import recaptcha from "~/mixins/recaptcha";
import { mapGetters } from "vuex";

export default {
  name: "RegistrationForms",
  components: {
    UserData,
    UserAddress,
    UserPasswordAndPrivacy,
    ResponseMessage
  },
  mixins: [recaptcha],
  data() {
    return {
      step: 0,
      isFiscalCode: true,
      isRegisterWithCard: null,
      isSocialLogin: false,
      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      userData: {
        fidelityCard: null,
        email: null,
        phone: null,
        billingAddress: {},
        person: {
          firstName: null,
          lastName: null,
          fiscalCode: null,
          gender: null,
          birthDate: null,
          birthPlace: null,
          personInfos: [
            { personInfoTypeId: 6, value: true },
            { personInfoTypeId: 7, value: true },
            { personInfoTypeId: 8, value: true },
            { personInfoTypeId: 14, value: "1" }
          ]
        }
      },
      checkAgreement: false,
      fidelityFound: false,
      askFirstName: true,
      askFiscalCode: true,
      askLastName: true,
      formStepId: null,
      response: {},
      trackId: new Date().getTime(),
      logTrackStr: this.trackId + " ",
      registrationType: "OldUser"
    };
  },
  computed: {
    progress() {
      return 34 * this.step;
    },
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    findUser() {
      this.userData.fidelityCard = this.userData.fidelityCard.replace(
        /[^0-9]/g,
        ""
      );
      let _this = this;
      TigrosRegistrationService.findUser(
        this.userData,
        this.checkAgreement
      ).then(function(response) {
        if (response.response.status == 0) {
          _this.fidelityFound = true;
          _this.askFiscalCode = response.data.askFiscalCode;
          _this.askFirstName = response.data.askFirstName;
          _this.askLastName = response.data.askLastName;

          if (!_this.askFiscalCode) {
            _this.userData.person.gender = "F";
          }
          _this.goToNextStep(1);
        }
        _this.response = response;
      });
    },

    onUserDataSubmit() {
      this.goToNextStep();
    },

    goToNextStep() {
      if (!this.userData.fidelityCard) {
        this.resetFormFields(this.isFiscalCode);
      }
      this.step = this.step + 1;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },

    goToPreviousStep(skipStepZero) {
      if (skipStepZero) {
        this.$router.push({ path: "/registration-user" });
      } else {
        this.step = this.step - 1;
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }
    },

    resetFormFields(isFiscalCode) {
      if (this.formStepId == 1) {
        if (isFiscalCode) {
          this.birthDate = null;
          this.userData.person.birthPlace = null;
        } else {
          this.userData.person.fiscalCode = null;
        }
      }
    },
    registerUser(oldCustomer, validBornDate) {
      if (validBornDate === false) {
        this.response = {
          errors: [{ error: "Formato della data di nascita non corretto" }]
        };
        return;
      }
      this.userData.checkAgreement = this.checkAgreement;
      this.userData.force = this.response.status === 2;
      let force = this.response.status === 2;
      // for (let i = 0; i < this.userData.person.personInfos.length; i++) {
      //   if (this.userData.person.personInfos[i].personInfoTypeId === 7) {
      //     this.userData.person.personInfos[i].value = 7;
      //   }
      //   if (this.userData.person.personInfos[i].personInfoTypeId === 8) {
      //     this.userData.person.personInfos[i].value = 8;
      //   }
      //   if (this.userData.person.personInfos[i].personInfoTypeId === 14) {
      //     this.userData.person.personInfos[i].value = parseInt(
      //       this.supportData.country.itemId
      //     );
      //   }
      // }
      setPersonInfo(this.userData.person, 7, this.supportData.privacy1);
      setPersonInfo(this.userData.person, 8, this.supportData.privacy2);
      setPersonInfo(
        this.userData.person,
        14,
        parseInt(this.supportData.country.itemId)
      );

      // fix for missing city
      if (!this.userData.billingAddress.city) {
        this.userData.billingAddress.city = "";
      }
      // fix province
      if (typeof this.userData.billingAddress.province === "object") {
        this.userData.billingAddress.province = this.userData.billingAddress.province.name;
      }

      this.userData.billingAddress.countryId = parseInt(
        this.supportData.country.itemId
      );

      console.log(this.userData);
      let _this = this;
      //TODO: verificare chiamata ad AnalyticsService
      TigrosRegistrationService.registerUser(this.userData).then(function(
        data
      ) {
        if (data.response.status == 0) {
          SecurityService.refreshLogin(true).then(function() {
            console.log(data);
            AnalyticsService.signUp(
              _this.isRegisterWithCard ? "card" : "nocard",
              data.data.user.userId
            );
            if (_this.isSocialLogin) {
              _this.$router.push({
                path: "/registration-social-completed",
                name: "RegistrationSocialCompleted",
                params: {
                  mail: _this.userData.login,
                  is_social_reg: true
                }
              });
            }
            if (force || _this.checkAgreement) {
              _this.$router.push({
                name: "RegistrationCompletedAgreement",
                params: { mail: _this.userData.email }
              });
            } else {
              _this.$router.push({
                name: "RegistrationCompleted",
                params: { mail: _this.userData.email }
              });
            }
          });
        } else {
          let error = data.response.data.response.errors[0].error;
          global.EventBus.$emit("error", {
            message: error
          });
          // _this.logTrackStr = _this.logTrackStr + "-Error";
          // AnalyticsService.trackEvent(
          //   "Registration",
          //   "Error",
          //   _this.trackId + " " + error
          // );
        }
      });
    },
    detectUserDetails() {
      var customerDetails = "";
      if (this.userData) {
        if (this.userData.email) {
          customerDetails = customerDetails + this.userData.email;
        }
        if (this.userData.fidelityCard) {
          if (customerDetails != "") {
            customerDetails = customerDetails + " ";
          }
          customerDetails = customerDetails + this.userData.fidelityCard;
        }
        if (customerDetails != "") {
          customerDetails = ": " + customerDetails;
        }
      }
      return customerDetails;
    }
  },
  mounted() {
    let socialLogin = this.$route.query.social_login;
    if (socialLogin) {
      //get social_user_detail
      SocialService.getSocialUserDetails().then(
        resp => {
          console.log("getSocialUserDetails:", resp);
          this.userData = resp.data.user;
          // set email field to readonly
          this.isSocialLogin = true;
        },
        function(err) {
          console.log("getSocialUserDetails ERROR:", err);
        }
      );
    }
    // this.loadDisclaimer();
  },
  created() {
    this.registrationType = this.$route.params.type || "OldUser";
    this.checkAgreement = this.$route.params.checkAgreement;
    this.isRegisterWithCard = this.$route.params.isRegisterWithCard;
    if (!this.isRegisterWithCard) this.step = 1;
  }
};
</script>
